//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import SingleContainer from "./SingleContainer";
export default {
  props: {
    visit: {
      type: Object,
      required: true
    }
  },
  computed: mapGetters('entities/shifts', ['ongoingShiftsUsers']),
  components: {
    SingleContainer: SingleContainer
  }
};