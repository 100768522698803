import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from "./routes";
import Loading from 'quasar/src/plugins/Loading.js';;
Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

var Router = new VueRouter({
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: routes,
  // Leave these as is and change from quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  mode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE
});
Router.afterEach(function () {
  Loading.hide();
});
Router.onError(function () {
  Loading.hide();
});
export default Router;