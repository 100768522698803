//
//
//
//
//
//
//
//
//
//
//

import CreatePatient from 'components/patients/Create';
// import UploadPatient from 'components/visits/patient/Upload'
import VisitsList from 'components/visits/List';
import Finances from 'components/finances/Finances';
export default {
  components: {
    // UploadPatient,
    CreatePatient: CreatePatient,
    VisitsList: VisitsList,
    Finances: Finances
  }
};