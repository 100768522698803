import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import filters from 'src/services/filters';
import ShiftList from "../partials/ShiftList.vue";
export default {
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('auth', ['user'])), mapGetters('entities/shifts', [
  // 'ongoingShifts',
  'todaysMedicalShifts', 'assistant'
  // 'doctors'
  ])), {}, {
    authAvatar: function authAvatar() {
      return process.env.API + this.user.avatar;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions('auth', [
  // 'toggleOption',
  'switchUser', 'doLogout'])), {}, {
    logout: function logout() {
      var _this = this;
      this.doLogout().finally(function () {
        _this.$router.push('/login');
      });
    }
  }),
  components: {
    ShiftList: ShiftList
  },
  filters: filters
};