import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.find.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Notify from 'quasar/src/plugins/Notify.js';;
import { mapGetters, mapActions } from 'vuex';
import SingleBase from "./SingleBase";
export default {
  mixins: [SingleBase],
  data: function data() {
    return {
      color: 'purple-2'
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('entities/exams', ['finished'])), {}, {
    exam: function exam() {
      var _this = this;
      return this.finished.find(function (exam) {
        return exam.type === _this.type && exam.visit_id === _this.visit_id;
      });
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions({
    update: 'entities/exams/sync'
  })), {}, {
    action: function action() {
      var _this2 = this;
      this.loading = true;
      return this.update({
        id: this.exam.id,
        finished_at: null
      }).then(function () {
        Notify.create({
          color: 'primary',
          icon: 'fal fa-info-circle',
          message: "Le retour de ".concat(_this2.type.toUpperCase(), " est annul\xE9. Le chrono est relanc\xE9.")
        });
      });
    }
  })
};