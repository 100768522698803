import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapMutations } from 'vuex';
import PatientShow from "./patient/Show";
import VisitHistory from "./history/History";
import VisitEditor from 'components/visits/history/VisitEditor';
import VisitStatus from "./Status";
import VisitProcedures from "./Procedures";
import VisitForm from "./form/Form.vue";
import filters from 'src/services/filters';
export default {
  props: {
    visit: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      loading: false,
      isValid: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('auth', {
    opened: function opened(s) {
      return s.ui.openExitForm;
    }
  })), mapGetters('entities/visits', ['query'])), {}, {
    expanded: function expanded() {
      return this.opened === this.visit.id;
    } // visit () {
    //     return this.query().with('consultations.exams').where('id', this.id).first()
    // }
  }),
  methods: _objectSpread({}, mapMutations('auth', ['toggleForm'])),
  filters: filters,
  components: {
    PatientShow: PatientShow,
    VisitEditor: VisitEditor,
    VisitHistory: VisitHistory,
    VisitStatus: VisitStatus,
    VisitProcedures: VisitProcedures,
    VisitForm: VisitForm
  }
};