import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.slice.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProcedureEditor from 'components/visits/history/ProcedureEditor';
import filters from 'src/services/filters';
import { mapState } from 'vuex';
import date from 'quasar/src/utils/date.js';;
var getDateDiff = date.getDateDiff;
export default {
  props: {
    procedure: {
      type: Object,
      required: true
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState('date', ['now'])), {}, {
    isDue: function isDue() {
      return getDateDiff(this.procedure.anticipatedEnd, this.now, 'minutes') < 0;
    },
    iconName: function iconName() {
      return this.isDue ? 'fas fa-history fa-flip-horizontal' : 'fal fa-history fa-flip-horizontal';
    },
    color: function color() {
      var grey = this.hexToRgb('#9e9e9e');
      // const accent = this.hexToRgb('#9c27b0')
      var warning = this.hexToRgb('#ffa000');
      var negative = this.hexToRgb('#f5365c');
      var value = getDateDiff(this.procedure.computedFinishedAt, this.now, 'minutes');
      if (value > 30) {
        return grey;
      }
      return this.interpolateColor(warning, negative, 1 - value / 30);
    }
  }),
  methods: {
    hexToRgb: function hexToRgb(hex) {
      var r = parseInt(hex.slice(1, 3), 16);
      var g = parseInt(hex.slice(3, 5), 16);
      var b = parseInt(hex.slice(5, 7), 16);
      return {
        r: r,
        g: g,
        b: b
      };
    },
    interpolateColor: function interpolateColor(color1, color2, factor) {
      if (factor < 0) factor = 0;
      if (factor > 1) factor = 1;
      var result = {
        r: Math.round(color1.r + factor * (color2.r - color1.r)),
        g: Math.round(color1.g + factor * (color2.g - color1.g)),
        b: Math.round(color1.b + factor * (color2.b - color1.b))
      };
      return "rgb(".concat(result.r, ", ").concat(result.g, ", ").concat(result.b, ")");
    }
  },
  filters: filters,
  components: {
    ProcedureEditor: ProcedureEditor
  }
};