// required
import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';
import '@fortawesome/fontawesome-pro/css/light.min.css';
// do you want these too?
// import '@fortawesome/fontawesome-pro/js/brands.min.js'
import '@fortawesome/fontawesome-pro/css/thin.min.css';
import '@fortawesome/fontawesome-pro/css/solid.min.css';
import '@fortawesome/fontawesome-pro/css/regular.min.css';

// leave the export, even if you don't use it
/* export default async ({ app, router, Vue }) => {
    // something to do
} */