import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    color: String,
    inactiveColor: String,
    icon: String,
    current: Object,
    choices: {
      type: Array,
      required: true
    }
  },
  computed: {
    hasSelection: function hasSelection() {
      var _this = this;
      return !!this.current && this.choices.some(function (choice) {
        return choice.name === _this.current.name;
      });
    }
  },
  methods: {
    toggle: function toggle(name) {
      this.currentRoom && this.visit.notes.room === name ? this.form.notes.room = null : this.form.notes.room = name;
      this.submit();
    },
    submit: function submit() {
      var _this2 = this;
      this.form.submit('entities/visits/sync').then(function () {
        return _this2.$store.dispatch('entities/visits/sort');
      });
    },
    removePriority: function removePriority() {
      this.form.notes.room = '';
      this.submit();
    }
  }
};