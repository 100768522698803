import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import Exam from 'src/store/models/Exam';
import date from 'quasar/src/utils/date.js';;
var addToDate = date.addToDate;
export function examsLabels(state, getters, rootState) {
  return (rootState.auth.settings.practice.exams || []).map(function (exam) {
    return exam.name;
  });
}
export function ongoing() {
  return Exam.query().where(function (exam) {
    return exam.isOngoing();
  }).get().sort(function (a, b) {
    return new Date(b.anticipatedEnd) - new Date(a.anticipatedEnd);
  });
}
export function finished() {
  return Exam.query().where(function (exam) {
    return exam.isFinished();
  }).get();
}
export function latestAnticipatedEnd() {
  return Exam.all().sort(function (a, b) {
    return new Date(b.anticipatedEnd) - new Date(a.anticipatedEnd);
  }).reduce(function (acc, exam) {
    return acc.some(function (v) {
      return v.visit_id === exam.visit_id;
    }) ? acc : acc.concat(exam);
  }, []);
}

/**
 *  {
 *      type: 'xxx',
 *      group: 'yyy',
 *      consultation_id: 'zzz',
 *      visit_id: 'fff'
 *  }
 */
export function upcomingList(state, getters, rootState) {
  return getters.ongoing.filter(function (exam) {
    return exam.anticipatedEnd > addToDate(rootState.date.now, {
      minutes: 30
    });
  }).reduce(function (acc, exam) {
    return acc.concat({
      type: exam.type,
      consultation_id: exam.consultation_id,
      visit_id: exam.visit_id
    });
  }, []);
}
export function finishedList(state, getters) {
  return getters.finished.reduce(function (acc, exam) {
    return acc.concat({
      type: exam.type,
      consultation_id: exam.consultation_id,
      visit_id: exam.visit_id
    });
  }, []);
}

// export function byGroup () {
//     return Exam.all()
//         .reduce((acc, exam) => {
//             const group = exam.group
//             const list = acc[group] ? acc[group] : []
//             return { ...acc, [group]: list.concat(exam.consultation_id) }
//         }, {})
// }

export function byConsultation() {
  return Exam.all().sort(function (a, b) {
    if (!a.finished_at) {
      return +1;
    }
    if (!b.finished_at) {
      return -1;
    }
    return new Date(a.finished_at) - new Date(b.finished_at);
  }).reduce(function (acc, exam) {
    var id = exam.consultation_id;
    var list = acc[id] ? acc[id] : [];
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, id, list.concat(exam)));
  }, {});
}

// export function startTimesByKey (state, getters) {
//     return getters.ongoing
//         .reduce((acc, exam) => {
//             return { ...acc, [`${exam.visit_id}-${exam.type}`]: exam.started_at }
//         }, {})
// }

export function startTimesByVisit(state, getters) {
  return getters.ongoing.sort(function (a, b) {
    return new Date(b.started_at) - new Date(a.started_at);
  }).reduce(function (acc, exam) {
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, "".concat(exam.visit_id, "-").concat(exam.type), exam.started_at));
  }, {});
}
export function finishTimeByVisit(state, getters) {
  return getters.finished.sort(function (a, b) {
    return new Date(a.finished_at) - new Date(b.finished_at);
  }).reduce(function (acc, exam) {
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, exam.visit_id, exam.finished_at));
  }, {});
}