import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    visit_id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      color: '',
      flat: true,
      loading: false
    };
  },
  methods: {
    baseAction: function baseAction() {
      var _this = this;
      this.loading = true;
      return this.action().finally(function () {
        _this.loading = false;
      });
    }
  }
};