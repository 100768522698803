//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import filters from 'src/services/filters';
import date from 'quasar/src/utils/date.js';;
var getDateDiff = date.getDateDiff;
export default {
  name: 'TimeRange',
  props: {
    procedure: Object
  },
  computed: {
    diff: function diff() {
      return getDateDiff(this.procedure.finished_at, this.procedure.started_at, 'minutes');
    }
  },
  filters: filters
};