import "core-js/modules/es6.regexp.search.js";
/*
export function someMutation (state) {
}
*/

export function index(state, index) {
  state.index = index;
}
export function setSearch(state, pattern) {
  state.search = pattern;
}