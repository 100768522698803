import { Database } from '@vuex-orm/core';
import User from "./models/User";
import Shift from "./models/Shift";
import Visit from "./models/Visit";
import Consultation from "./models/Consultation";
import Exam from "./models/Exam";
import Stat from "./models/Stat";
import visits from "./modules/visits";
// import users from './modules/users'
import shifts from "./modules/shifts";
import consultations from "./modules/consultations";
import exams from "./modules/exams";
import stats from "./modules/stats";
var database = new Database();
database.register(User);
database.register(Shift, shifts);
database.register(Visit, visits);
database.register(Consultation, consultations);
database.register(Exam, exams);
database.register(Stat, stats);
export default database;