import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.string.starts-with.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapGetters } from 'vuex';
export default {
  computed: _objectSpread(_objectSpread({}, mapGetters({
    total: 'entities/visits/billed'
  })), {}, {
    turnover: function turnover() {
      // CA
      return this.billed.reduce(function (acc, visit) {
        return acc + Number(visit.profit);
      }, 0).toFixed(2);
    },
    wireSum: function wireSum() {
      return this.billed.reduce(function (acc, visit) {
        return acc + Number(visit.wire);
      }, 0).toFixed(2);
    },
    deskSum: function deskSum() {
      return this.billed.reduce(function (acc, visit) {
        return acc + Number(visit.desk);
      }, 0).toFixed(2);
    },
    cashSum: function cashSum() {
      return this.billed.filter(function (visit) {
        return visit.payment.method === 'cash';
      }).reduce(function (acc, visit) {
        return acc + Number(visit.desk);
      }, 0).toFixed(2);
    },
    unpaid: function unpaid() {
      return this.billed.filter(function (v) {
        return ['unpaid', 't-unpaid'].includes(v.payment.method);
      });
    },
    unpaidCount: function unpaidCount() {
      return this.unpaid.length;
    },
    unpaidSum: function unpaidSum() {
      return this.unpaid.reduce(function (acc, v) {
        return v.payment.method === 'unpaid' ? acc + Number(v.invoice) : acc + Number(v.third);
      }, 0).toFixed(2);
    },
    cashCount: function cashCount() {
      return this.billed.filter(function (visit) {
        return visit.payment.method === 'cash';
      }).length;
    },
    bqCount: function bqCount() {
      return this.billed.filter(function (v) {
        return v.payment.method === 'bq';
      }).map(function (v) {
        return v.payment.count;
      }).filter(function (visit, index, self) {
        // Unique BQ Number (In case of BQ paying for several patients)
        return self.indexOf(visit) === index;
      }).length;
    },
    bqPatients: function bqPatients() {
      return this.billed.filter(function (v) {
        return v.payment.method === 'bq';
      }).length;
    },
    bqSum: function bqSum() {
      return this.billed.filter(function (visit) {
        return visit.payment.method === 'bq';
      }).reduce(function (acc, visit) {
        return acc + Number(visit.desk);
      }, 0).toFixed(2);
    },
    ccCount: function ccCount() {
      return this.billed.filter(function (visit) {
        return visit.payment.method === 'cc';
      }).map(function (v) {
        return v.payment.count;
      }).filter(function (visit, index, self) {
        // Unique BQ Number (In case of BQ paying for several patients)
        return self.indexOf(visit) === index;
      }).length;
    },
    ccPatients: function ccPatients() {
      return this.billed.filter(function (v) {
        return v.payment.method === 'cc';
      }).length;
    },
    ccSum: function ccSum() {
      return this.billed.filter(function (visit) {
        return visit.payment.method === 'cc';
      }).reduce(function (acc, visit) {
        return acc + Number(visit.desk);
      }, 0).toFixed(2);
    },
    tcCount: function tcCount() {
      return this.billed.filter(function (visit) {
        return !!visit.quote && visit.quote.startsWith('t');
      }).length;
    },
    commission: function commission() {
      var _this = this;
      var turnover = 0;
      var commission = 0;
      this.total.sort(function (a, b) {
        return new Date(a.finished_at) - new Date(b.finished_at);
      }).map(function (visit) {
        turnover += Number(visit.invoice);
        if (_this.billed.some(function (v) {
          return v.id === visit.id;
        })) {
          if (turnover >= 2400 || new Date(visit.finished_at).getHours() >= 20) {
            commission += visit.invoice * 0.65;
          } else {
            commission += visit.invoice * 0.60;
          }
        }
      });
      return commission.toFixed(2);
    }
  })
};