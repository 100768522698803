import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Form from 'src/services/form';
import AppSelect from 'src/mixins/app/Select';
import filters from 'src/services/filters';
var time = filters.time;
export default {
  name: 'ProcedureEditor',
  props: {
    procedure: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      menu: false,
      confirm: false,
      loading: false,
      form: new Form(_objectSpread(_objectSpread({}, this.procedure), {}, {
        started_at: this.getDefault('started_at'),
        finished_at: this.getDefault('finished_at')
      }))
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    consultationsWoExam: 'entities/consultations/withoutExam',
    // doctors: 'entities/shifts/doctors',
    examsValues: 'entities/exams/examsLabels',
    shifts: 'entities/shifts/query'
  })), {}, {
    isConsultation: function isConsultation() {
      return 'user_id' in this.procedure;
    },
    isTrashable: function isTrashable() {
      var _this = this;
      return this.isConsultation ? this.consultationsWoExam.some(function (consult) {
        return consult.id === _this.procedure.id;
      }) : true; // Exam are always trashables.
    },
    userOptions: function userOptions() {
      var shifts = this.shifts().has('user').with('user').get().filter(function (s) {
        return s.isMedical();
      }).sort(function (a, b) {
        return a.type.id - b.type.id;
      });
      return !this.$route.params.date ? shifts.map(function (s) {
        return s.user;
      }) : shifts.filter(function (s) {
        return s.start_at < new Date().setHours(0, 0, 0, 0);
      }).map(function (s) {
        return s.user;
      });
    },
    examOptions: function examOptions() {
      return this.examsValues.map(function (exam) {
        return {
          value: exam,
          label: exam.toUpperCase()
        };
      });
    },
    typeInWords: function typeInWords() {
      if (!this.procedure.type) {
        return '';
      }
      return {
        rx: 'de Radio',
        echo: 'd\'Echo',
        scan: 'de Scanner',
        irm: 'd\'IRM',
        labo: 'de Biologie',
        surv: 'de surveillance',
        hosp: 'd\'Hospitalisation',
        smur: 'de SMUR'
      }[this.procedure.type];
    }
  }),
  methods: {
    getDefault: function getDefault(attr) {
      if (attr in this.procedure === false) {
        return '';
      }
      if (this.procedure[attr] === null) {
        return '';
      }
      return ['started_at', 'finished_at'].includes(attr) ? time(this.procedure[attr]) : this.procedure[attr];
    },
    getAction: function getAction() {
      return 'user_id' in this.procedure ? 'entities/consultations/sync' : 'entities/exams/sync';
    },
    submit: function submit() {
      var _this2 = this;
      this.form.submit(this.getAction()).then(this.$refs.menu.hide()).catch(function () {
        return _this2.form.clear();
      });
    },
    remove: function remove() {
      var _this3 = this;
      this.loading = true;
      var action = this.isConsultation ? 'entities/consultations/remove' : 'entities/exams/remove';
      return this.$store.dispatch(action, this.procedure.id).finally(function () {
        _this3.loading = false;
        _this3.confirm = false;
      });
    }
  },
  filters: filters,
  components: {
    AppSelect: AppSelect
  }
};