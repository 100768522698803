import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import Shift from 'src/store/models/Shift';
import User from 'src/store/models/User';
import date from 'quasar/src/utils/date.js';;
var subtractFromDate = date.subtractFromDate,
  addToDate = date.addToDate,
  getMinDate = date.getMinDate,
  buildDate = date.buildDate,
  getDateDiff = date.getDateDiff;
export function todaysMedicalShifts() {
  return Shift.query().with('user').where(function (shift) {
    return shift.isMedical() && getDateDiff(shift.start_at, new Date(), 'days') === 0;
  }).get().sort(function (a, b) {
    return a.type.id - b.type.id;
  });
}
export function pastShifts() {
  return Shift.query().with('user').where(function (shift) {
    return getDateDiff(shift.start_at, new Date(), 'days') < 0;
  }).get().sort(function (a, b) {
    return a.type.id - b.type.id;
  });
}

// export function doctors (state, getters) {
//     return getters.todaysMedicalShifts
//         .map(shift => ({ ...shift.user, shift: shift }))
//         .filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i) // Unique.
// }

export function ongoingShifts(state, getters, rootState, rootGetters) {
  return getters.todaysMedicalShifts.filter(function (shift) {
    if (getters.todaysMedicalShifts.length <= 1) {
      return true;
    }
    if (rootGetters['entities/consultations/ongoing'].some(function (c) {
      return c.user_id === shift.user_id;
    })) {
      return true;
    }
    var shiftVisibilityIndex = rootState.auth.ui.visibilities.shifts.findIndex(function (s) {
      return s.id === shift.id;
    });
    if (shiftVisibilityIndex !== -1) {
      return rootState.auth.ui.visibilities.shifts[shiftVisibilityIndex].visibility;
    }

    // Affichage des médecins 90 minutes avant et 90 min après la garde ou à minuit au max.
    var endOfDay = buildDate({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999
    });
    var now = rootState.date.now.getTime();
    var from = subtractFromDate(shift.start_at, {
      minutes: 30
    }).getTime();
    var to = getMinDate(addToDate(shift.end_at, {
      minutes: 30
    }), endOfDay);
    if (now > to || now < from) {
      return false;
    }
    if (shift.type.id >= 20) {
      return true;
    }
    return !getters.todaysMedicalShifts.some(function (child) {
      if (child.event_id !== shift.id) {
        return false;
      }
      if (child.start_at.getTime() === shift.start_at.getTime() && child.end_at.getTime() === shift.end_at.getTime()) {
        return true;
      }
      if (child.start_at.getTime() === shift.start_at.getTime() && subtractFromDate(child.end_at, {
        minutes: 30
      }).getTime() >= now) {
        return true;
      }
      if (child.end_at.getTime() === shift.end_at.getTime() && addToDate(child.start_at, {
        minutes: 30
      }).getTime() <= now) {
        return true;
      }
      return false;
    });
  });
}
export function ongoingShiftsUsers(state, getters) {
  return getters.ongoingShifts.map(function (shift) {
    return User.find(shift.user_id);
  }).filter(function (v, i, s) {
    return s.indexOf(v) === i;
  }); // Unique.
}

export function assistant() {
  var shift = Shift.query().with('user').where(function (shift) {
    return shift.isAssistant();
  }).first();
  return shift ? shift.user : {
    avatarUrl: 'https://ucarecdn.com/afbed8c0-db48-4571-af0f-da29ad375023/',
    fullname: 'Personne'
  };
}
export function withBilledVisits(state, getters) {
  return Shift.query().with(['user', 'parent.user']).has('visits').get().reduce(function (acc, shift) {
    if (!acc.seen.has(shift.financialkey)) {
      acc.shifts.push(shift);
      acc.seen.add(shift.financialkey);
    }
    return acc;
  }, {
    shifts: [],
    seen: new Set()
  }).shifts.sort(function (a, b) {
    return a.type.id - b.type.id;
  });

  // return rootGetters['entities/visits/billed']
  //     .reduce((acc, visit) => {
  //         if (visit.permanent_id === null) {
  //             return acc
  //         }

  //         const seen = acc.seen || new Set()

  //         if (!seen.has(visit.permanent_id)) {
  //             acc.shifts.push(visit.shift)
  //             seen.add(visit.permanent_id)
  //         }

  //         return acc
  //     }, { shifts: [], seen: new Set() })
  //     .shifts
  //     .sort((a, b) => a.type.id - b.type.id)
}