import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Notify from 'quasar/src/plugins/Notify.js';;
import Form from 'src/services/form';
export default {
  data: function data() {
    return {
      naming: false,
      loading: false,
      form: new Form({
        started_at: null,
        patient: {
          firstname: '',
          lastname: ''
        },
        notes: {
          priority: '',
          origin: ''
        }
      })
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    priorities: function priorities(s) {
      return s.auth.settings.practice.priority_cares;
    },
    originsOrder: function originsOrder(s) {
      return s.auth.settings.originsOrder;
    },
    practiceOptions: function practiceOptions(s) {
      return s.auth.settings.practice.statistics;
    }
  })), mapGetters('auth', ['originOptions'])), {}, {
    topFirstOrigins: function topFirstOrigins() {
      var _this = this;
      return this.originsOrder.slice(0, 3).map(function (origin) {
        return _this.originOptions.find(function (option) {
          return origin.name === option.name;
        });
      });
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions('entities/visits', ['store', 'sort'])), {}, {
    clicOrigin: function clicOrigin(name) {
      this.form.notes.origin = name;
      this.$refs.nameInput.focus();
    },
    submitTc: function submitTc() {
      this.form.is_appointment = true;
      this.form.notes.room = 't';
      this.submit();
    },
    submitChild: function submitChild() {
      this.form.notes.child = true;
      this.submit();
    },
    submitWithPriority: function submitWithPriority(name) {
      this.form.notes.priority = name;
      this.submit();
    },
    submit: function submit() {
      var _this2 = this;
      this.loading = true;
      this.form.submit('entities/visits/store').then(function (response) {
        _this2.form.clear();
        Notify.create({
          color: 'positive',
          icon: 'fal fa-check-circle',
          message: "Nouveau patient ajout\xE9 \xE0 la salle d'attente"
        });
      }).finally(function () {
        _this2.loading = false;
        _this2.sort();
      });
    }
  })
};