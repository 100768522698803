import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loading from 'quasar/src/plugins/Loading.js';;
import { mapActions, mapState } from 'vuex';
export default {
  computed: _objectSpread({}, mapState({
    // now: s => s.date.now,
    options: function options(s) {
      return s.auth.settings.timemachineOptions;
    }
    // isAdmin: s => s.auth.user.data.is_admin,
    // shifts: s => s.auth.user.shifts
  })),

  methods: _objectSpread(_objectSpread({}, mapActions({
    fetch: 'entities/visits/fetchByDate',
    sort: 'entities/visits/sort'
  })), {}, {
    // options (date) {
    //     return getDateDiff(new Date(), new Date(date), 'days') > 0
    // },
    input: function input(value) {
      var _this = this;
      Loading.show({
        delay: 0
        // message: messages[Math.floor(Math.random() * messages.length)]
      });

      return new Promise(function (resolve) {
        _this.$router.push('/timemachine/' + value);
      }).then(function () {
        _this.$refs.qDateMenu.hide();
        Loading.hide();
      });
    }
  })
};