import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import PreConsultationStates from 'components/visits/PreConsultationStates';
import Form from 'src/services/form';
export default {
  props: {
    visit: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      // payment_method send alongside notes because of validate in.
      // validation will remove count if payment_method is missing.
      form: new Form({
        id: this.visit.id,
        // payment_method: this.visit.payment_method,
        notes: _objectSpread({
          room: '',
          priority: ''
        }, this.visit.notes)
      })
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState({
    rooms: function rooms(s) {
      return s.auth.settings.practice.rooms;
    },
    priorityCares: function priorityCares(s) {
      return s.auth.settings.practice.priority_cares;
    }
  })), mapGetters('auth', ['consultationRooms', 'secondaryRooms'])), mapGetters('entities/visits', ['inRooms'])), mapGetters('entities/consultations', ['ongoing'])), {}, {
    currentRoom: function currentRoom() {
      var _this = this;
      return 'room' in this.visit.notes ? this.rooms.find(function (r) {
        return r.name === _this.visit.notes.room;
      }) : null;
    },
    currentPriority: function currentPriority() {
      var _this2 = this;
      return 'priority' in this.visit.notes ? this.priorityCares.find(function (p) {
        return p.name === _this2.visit.notes.priority;
      }) : null;
    },
    activeColor: function activeColor() {
      var _this3 = this;
      var consultation = this.ongoing.some(function (consult) {
        return consult.visit_id === _this3.visit.id;
      });
      if (!consultation) {
        return 'negative';
      }
      return this.currentRoom ? this.currentRoom.color : 'primary';
    },
    inactiveColor: function inactiveColor() {
      return this.currentRoom ? 'grey-4' : 'grey-5';
    }
  }),
  methods: {
    isCurrentRoom: function isCurrentRoom(name) {
      return !!this.currentRoom && this.currentRoom.name === name;
    },
    toggleRoom: function toggleRoom(name) {
      this.toggle('room', name);
    },
    togglePriority: function togglePriority(name) {
      this.toggle('priority', name);
    },
    toggle: function toggle(type, name) {
      var current = type === 'room' ? this.currentRoom : this.currentPriority;
      current && this.visit.notes[type] === name ? this.form.notes[type] = null : this.form.notes[type] = name;
      this.submit();
    },
    submit: function submit() {
      var _this4 = this;
      this.form.submit('entities/visits/sync').then(function () {
        return _this4.$store.dispatch('entities/visits/sort');
      });
    },
    removePriority: function removePriority() {
      this.form.notes.room = '';
      this.submit();
    }
  },
  components: {
    PreConsultationStates: PreConsultationStates
  }
};