//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PaymentMethodCounter',
  props: {
    payment: Object
  },
  data: function data() {
    return {
      mutableCount: this.payment.count
    };
  },
  methods: {
    incrementCount: function incrementCount() {
      this.mutableCount++;
      this.$emit('update:count', this.mutableCount);
    },
    decrementCount: function decrementCount() {
      this.mutableCount = Math.max(this.mutableCount - 1, 1);
      this.$emit('update:count', this.mutableCount);
    }
  }
};