//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TimeRange from 'components/partials/TimeRange';
import ProcedureEditor from "./ProcedureEditor";
export default {
  name: 'ExamsHistory',
  props: {
    visit: {
      type: Object,
      required: true
    },
    consultation: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    exams: function exams() {
      var _this = this;
      return this.$store.getters['entities/exams']().where(function (exam) {
        return exam.consultation_id === _this.consultation.id;
      }).get();
    },
    avatarColor: function avatarColor() {
      if (this.readonly) {
        return 'trensparent';
      }
      return this.visit.isOngoing() ? 'bg-grey-1' : 'bg-blue-grey-2';
    }
  },
  components: {
    TimeRange: TimeRange,
    ProcedureEditor: ProcedureEditor
  }
};