import date from 'quasar/src/utils/date.js';;
var extractDate = date.extractDate;
export function watch(_ref) {
  var commit = _ref.commit;
  commit('updateNow', new Date());

  // Reactive Time.
  var interval = setInterval(function () {
    commit('updateNow', new Date());
  }, 20 * 1000);
  commit('setInterval', interval);
}
export function timemachine(_ref2, ymd) {
  var commit = _ref2.commit,
    state = _ref2.state;
  var date = extractDate(ymd, 'YYYY-MM-DD');
  clearInterval(state.interval);
  commit('updateNow', date);
}