import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'src/services/form';
import SinceDuration from 'components/partials/SinceDuration';
import { mapState, mapGetters } from 'vuex';
export default {
  props: {
    visit: Object
  },
  data: function data() {
    return {
      form: new Form({
        id: this.visit.id,
        notes: {
          child: this.visit.notes.child
        },
        patient: _objectSpread({}, this.visit.patient)
      })
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters('entities/visits', ['groupedByRelation'])), mapState({
    options: function options(s) {
      return s.auth.settings.practice.statistics;
    }
  })), {}, {
    isChild: function isChild() {
      return this.form.notes.child;
    },
    relations: function relations() {
      return this.groupedByRelation[this.visit.minuteKey];
    },
    relationNames: function relationNames() {
      var _this = this;
      return this.relations.filter(function (v) {
        return v.id !== _this.visit.id;
      }).map(function (v) {
        return v.patient.fullname;
      });
    },
    relationColor: function relationColor() {
      var string = this.relations.reduce(function (acc, v) {
        return acc + v.id;
      });
      var hash = 0;
      for (var _i = 0; _i < string.length; _i++) {
        hash = string.charCodeAt(_i) + ((hash << 5) - hash);
        hash = hash & hash;
      }
      var color = '#';
      for (var i = 0; i < 3; i++) {
        var value = hash >> i * 8 & 255;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    }
  }),
  methods: {
    switchAge: function switchAge() {
      this.form.notes.child = !this.form.notes.child;
      this.form.submit('entities/visits/sync');
    }
  },
  components: {
    SinceDuration: SinceDuration
  }
};