import Vue from 'vue';
import Vuex from 'vuex';
import auth from "./modules/auth";
import date from "./modules/date";
import VuexORM from '@vuex-orm/core';
import database from "./database";
Vue.use(Vuex);
var Store = new Vuex.Store({
  modules: {
    auth: auth,
    date: date
  },
  plugins: [VuexORM.install(database)]
});
export default Store;