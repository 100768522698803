//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'src/services/form';
import filters from 'src/services/filters';
// import AppTooltip from 'src/mixins/app/Tooltip'

var time = filters.time;
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    doctor: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      loading: false,
      disabled: false,
      flat: true,
      confirmEnd: false,
      form: new Form({
        id: this.model.id,
        started_at: time(this.model.started_at)
      })
    };
  },
  computed: {
    color: function color() {
      return '';
    }
  },
  methods: {
    remove: function remove() {
      this.$store.dispatch('entities/consultations/remove', this.model.id);
    }
  }
};