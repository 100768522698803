import DefaultLayout from 'layouts/Default.vue';
import Dashboard from 'pages/Dashboard.vue';
// import Finances from 'pages/Finances.vue'
import store from 'src/store';
import date from 'quasar/src/utils/date.js';;
var isValid = date.isValid;
var routes = [{
  path: '/',
  component: DefaultLayout,
  children: [{
    path: '',
    redirect: 'dashboard'
  }, {
    path: 'dashboard',
    name: 'dashboard',
    component: Dashboard
  }
  // { path: 'finances', name: 'finances', component: Finances }
  // { path: '/stats', name: 'stats', component: () => import('pages/Stats.vue') }
  ]
}, {
  path: '/timemachine/:date',
  component: function component() {
    return import('layouts/TimeMachine.vue');
  },
  beforeEnter: function beforeEnter(to, from, next) {
    if (isValid(to.params.date)) {
      next();
    } else {
      next({
        name: '404'
      }); // redirect to 404
    }
  },

  children: [{
    path: '',
    'name': 'timemachine',
    component: function component() {
      return import('pages/TimeMachine.vue');
    }
  }]
}, {
  path: '/login',
  name: 'Login',
  component: function component() {
    return import('layouts/Login.vue');
  }
}, {
  path: '/logout',
  beforeEnter: function beforeEnter(tp, from, next) {
    store.dispatch('auth/doLogout');
    next('/login');
  }
}, {
  path: '*',
  name: '404',
  component: function component() {
    return import('pages/Error404.vue');
  }
}];
export default routes;