import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import ExamsHistory from "./ExamsHistory";
import TimeRange from 'components/partials/TimeRange';
import VisitEditor from 'components/visits/history/VisitEditor';
import ProcedureEditor from 'components/visits/history/ProcedureEditor';
import filters from 'src/services/filters';

// const { time } = filters

export default {
  props: {
    visit: Object,
    dense: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    isAdmin: function isAdmin(s) {
      return s.auth.user.is_admin;
    },
    rooms: function rooms(s) {
      return s.auth.settings.practice.rooms;
    },
    priorityCares: function priorityCares(s) {
      return s.auth.settings.practice.priority_cares;
    }
  })), mapGetters('entities/visits', ['inWaitingLine', 'returners'])), {}, {
    isInWaitingLine: function isInWaitingLine() {
      var _this = this;
      return this.inWaitingLine.some(function (v) {
        return v.id === _this.visit.id;
      });
    },
    waitingLineIcon: function waitingLineIcon() {
      var _this2 = this;
      if (this.visit.priority > 1) {
        var priority = this.priorityCares.find(function (p) {
          return p.name === _this2.visit.notes.priority;
        });
        return 'fas ' + priority.icon;
      }
      if (this.visit.priority > 0) {
        var room = this.rooms.find(function (r) {
          return r.name === _this2.visit.notes.room;
        });
        return 'fal ' + room.icon;
      }
      if (this.visit.isDistant()) {
        return 'fal fa-headset';
      }
      if (this.returners.some(function (v) {
        return v.id === _this2.visit.id;
      })) {
        return 'fal fa-hourglass-half';
      }
      if (this.visit.is_appointment) {
        return 'fal fa-hourglass-clock';
      }
      return 'fal fa-hourglass-start';
    },
    color: function color() {
      var _this3 = this;
      var room = this.rooms.find(function (r) {
        return r.name === _this3.visit.notes.room;
      });
      return room ? room.color : 'primary';
    },
    avatarColor: function avatarColor() {
      if (this.readonly) {
        return 'transparent';
      }
      return this.visit.isOngoing() ? 'blue-grey-1' : 'blue-grey-2';
    }
  }),
  filters: filters,
  components: {
    ExamsHistory: ExamsHistory,
    TimeRange: TimeRange,
    VisitEditor: VisitEditor,
    ProcedureEditor: ProcedureEditor
  }
};