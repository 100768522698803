import { register } from 'register-service-worker';

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter
  // registrationOptions: { scope: './' },
  ready: function ready() {
    // console.log('Service worker is active.')
  } /* registration */,
  registered: function registered() {
    // console.log('Service worker has been registered.')
  } /* registration */,
  cached: function cached() {
    // console.log('Content has been cached for offline use.')
  } /* registration */,
  updatefound: function updatefound() {
    // console.log('New content is downloading.')
  } /* registration */,
  updated: function updated() {
    // console.log('New content is available; please refresh.')
  } /* registration */,
  offline: function offline() {
    // console.log('No internet connection found. App is running in offline mode.')
  },
  error: function error() {
    // console.error('Error during service worker registration:', err)
  } /* err */
});