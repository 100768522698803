import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
export default (function (_ref) {
  var Vue = _ref.Vue,
    router = _ref.router;
  Sentry.init({
    Vue: Vue,
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', process.env.URL, /^\//]
    })],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
});