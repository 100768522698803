import "core-js/modules/es6.array.find.js";
import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
import Notify from 'quasar/src/plugins/Notify.js';;
import { mapGetters } from 'vuex';
import SingleBase from "./SingleBase";
export default {
  mixins: [SingleBase],
  computed: mapGetters({
    find: 'entities/visits/find',
    ongConsultations: 'entities/consultations/ongoing',
    consultationsWoExam: 'entities/consultations/withoutExam'
  }),
  methods: {
    action: function action() {
      var _this = this;
      this.loading = true;
      var consultation = this.consultationsWoExam.find(function (c) {
        return c.isFinished() && c.visit_id === _this.model.id;
      });
      if (consultation) {
        var visit = this.find(consultation.visit_id);
        return this.$store.dispatch('entities/consultations/update', {
          id: consultation.id,
          user_id: this.doctor.id,
          finished_at: null
        }).then(function () {
          Notify.create({
            color: 'primary',
            icon: 'fal fa-info-circle',
            message: "La consultation de ".concat(visit.patient.fullname, " a \xE9t\xE9 relanc\xE9e.")
          });
        }).finally(function () {
          _this.loading = false;
        });
      }
      this.$store.dispatch('entities/consultations/start', {
        visit_id: this.model.id,
        user_id: this.doctor.id
      }).finally(function () {
        _this.loading = false;
      });
    }
  }
};